import React from "react";

function Padding({size}) {


    return (
        <div style={{ height: size, width: "100%" }}>
        </div>
    );

}

export default Padding;